import { default as invalid_45password_45reset_45tokenktfsjCuD1hMeta } from "/opt/buildhome/repo/pages/auth/invalid-password-reset-token.vue?macro=true";
import { default as _91accountSlug_93IQDENaGAtJMeta } from "/opt/buildhome/repo/pages/auth/login/[accountSlug].vue?macro=true";
import { default as indexzLfJUIfcqEMeta } from "/opt/buildhome/repo/pages/auth/login/index.vue?macro=true";
import { default as logoutgV6ePTKB4cMeta } from "/opt/buildhome/repo/pages/auth/logout.vue?macro=true";
import { default as request_45password_45reset_45confirmation4Gcj0ysSjJMeta } from "/opt/buildhome/repo/pages/auth/request-password-reset-confirmation.vue?macro=true";
import { default as request_45password_45resetei2CFiaJGXMeta } from "/opt/buildhome/repo/pages/auth/request-password-reset.vue?macro=true";
import { default as reset_45password_45confirmation7ZP7mxXXdRMeta } from "/opt/buildhome/repo/pages/auth/reset-password-confirmation.vue?macro=true";
import { default as reset_45passwordh7H23OMrjXMeta } from "/opt/buildhome/repo/pages/auth/reset-password.vue?macro=true";
import { default as settingsTPNI77q8aXMeta } from "/opt/buildhome/repo/pages/auth/settings.vue?macro=true";
import { default as _91hash_93VyRD9fQviQMeta } from "/opt/buildhome/repo/pages/auth/verify-email/[userId]/[hash].vue?macro=true";
import { default as _91categoryId_93jYT1O5LNONMeta } from "/opt/buildhome/repo/pages/categories/[categoryId].vue?macro=true";
import { default as _91salesChannelId_93xJfQUhtm1VMeta } from "/opt/buildhome/repo/pages/categories/create/[salesChannelId].vue?macro=true";
import { default as index6yDS2Nat0aMeta } from "/opt/buildhome/repo/pages/categories/index.vue?macro=true";
import { default as categoriesi2G3xcJEDRMeta } from "/opt/buildhome/repo/pages/categories.vue?macro=true";
import { default as codesIh6NUVaI4CMeta } from "/opt/buildhome/repo/pages/discounts/[discountId]/codes.vue?macro=true";
import { default as indexIbxEE3ANTPMeta } from "/opt/buildhome/repo/pages/discounts/[discountId]/index.vue?macro=true";
import { default as createw45JYHHXSXMeta } from "/opt/buildhome/repo/pages/discounts/create.vue?macro=true";
import { default as index5GWJdjIdApMeta } from "/opt/buildhome/repo/pages/discounts/index.vue?macro=true";
import { default as _91enhancementId_93cYbUCuXFEjMeta } from "/opt/buildhome/repo/pages/enhancements/[enhancementId].vue?macro=true";
import { default as createaeA6tXEPKDMeta } from "/opt/buildhome/repo/pages/enhancements/create.vue?macro=true";
import { default as indexNhIH2KORLkMeta } from "/opt/buildhome/repo/pages/enhancements/index.vue?macro=true";
import { default as enhancementsyXoJzs6HxBMeta } from "/opt/buildhome/repo/pages/enhancements.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91invitationId_93cqHngTrnl4Meta } from "/opt/buildhome/repo/pages/invitation/[invitationId].vue?macro=true";
import { default as invalidhJxTarEej9Meta } from "/opt/buildhome/repo/pages/invitation/invalid.vue?macro=true";
import { default as _91orderId_935DI3UWSJJoMeta } from "/opt/buildhome/repo/pages/orders/[orderId].vue?macro=true";
import { default as indexfn0Xu6bTriMeta } from "/opt/buildhome/repo/pages/orders/index.vue?macro=true";
import { default as orderspVyfxG93eVMeta } from "/opt/buildhome/repo/pages/orders.vue?macro=true";
import { default as indexR53ivaqxxAMeta } from "/opt/buildhome/repo/pages/products/[productId]/index.vue?macro=true";
import { default as _91variantId_93AIoZKTEytuMeta } from "/opt/buildhome/repo/pages/products/[productId]/variants/[variantId].vue?macro=true";
import { default as create9PiAkKkhybMeta } from "/opt/buildhome/repo/pages/products/[productId]/variants/create.vue?macro=true";
import { default as _91type_93desB2ZN3aaMeta } from "/opt/buildhome/repo/pages/products/create/[type].vue?macro=true";
import { default as createjt4eqUyGuqMeta } from "/opt/buildhome/repo/pages/products/create/variants/create.vue?macro=true";
import { default as indexfRsRvEd4TiMeta } from "/opt/buildhome/repo/pages/products/index.vue?macro=true";
import { default as products2T6Jgugpz6Meta } from "/opt/buildhome/repo/pages/products.vue?macro=true";
import { default as indexGevHmlQqv9Meta } from "/opt/buildhome/repo/pages/reports/index.vue?macro=true";
import { default as reportsXblRqgf8cKMeta } from "/opt/buildhome/repo/pages/reports.vue?macro=true";
import { default as billinga8ZfFn34gNMeta } from "/opt/buildhome/repo/pages/settings/billing.vue?macro=true";
import { default as _91hostname_93GmbGgIj5x2Meta } from "/opt/buildhome/repo/pages/settings/email-domains/[hostname].vue?macro=true";
import { default as indexXuTd1zRNxGMeta } from "/opt/buildhome/repo/pages/settings/email-domains/index.vue?macro=true";
import { default as email_45domainsnKawtjh0CKMeta } from "/opt/buildhome/repo/pages/settings/email-domains.vue?macro=true";
import { default as generalOlxGhMSA1zMeta } from "/opt/buildhome/repo/pages/settings/general.vue?macro=true";
import { default as indexGjvb4dkXLuMeta } from "/opt/buildhome/repo/pages/settings/index.vue?macro=true";
import { default as paypalWXNvW21PQDMeta } from "/opt/buildhome/repo/pages/settings/payment-gateways/connect/paypal.vue?macro=true";
import { default as stripe0mfr5MC9VLMeta } from "/opt/buildhome/repo/pages/settings/payment-gateways/connect/stripe.vue?macro=true";
import { default as indexEg336BPiopMeta } from "/opt/buildhome/repo/pages/settings/payment-gateways/index.vue?macro=true";
import { default as _91redeemLocationId_93CUsh3mnPNqMeta } from "/opt/buildhome/repo/pages/settings/redeem-locations/[redeemLocationId].vue?macro=true";
import { default as createC5x3BIxsKGMeta } from "/opt/buildhome/repo/pages/settings/redeem-locations/create.vue?macro=true";
import { default as indexgy53kswKBzMeta } from "/opt/buildhome/repo/pages/settings/redeem-locations/index.vue?macro=true";
import { default as redeem_45locations3gtKCkroFgMeta } from "/opt/buildhome/repo/pages/settings/redeem-locations.vue?macro=true";
import { default as _91userId_93NJ7Scxsv9AMeta } from "/opt/buildhome/repo/pages/settings/users/[userId].vue?macro=true";
import { default as emailQtiL0mCV9BMeta } from "/opt/buildhome/repo/pages/settings/users/create/email.vue?macro=true";
import { default as usernameOsWKIvA88HMeta } from "/opt/buildhome/repo/pages/settings/users/create/username.vue?macro=true";
import { default as indexXu5KbXbpH1Meta } from "/opt/buildhome/repo/pages/settings/users/index.vue?macro=true";
import { default as _91invitationId_93JbuuAPgcJvMeta } from "/opt/buildhome/repo/pages/settings/users/invitations/[invitationId].vue?macro=true";
import { default as index0vkw1WneBhMeta } from "/opt/buildhome/repo/pages/settings/users/invitations/index.vue?macro=true";
import { default as userszZkKEWjH4lMeta } from "/opt/buildhome/repo/pages/settings/users.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
import { default as _91page_93Z4A7VPj7CtMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/emails/[page].vue?macro=true";
import { default as indexyjBpFydHOCMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/emails/index.vue?macro=true";
import { default as faqVYFqPNpphUMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/faq.vue?macro=true";
import { default as indexjH785KxkYCMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/index.vue?macro=true";
import { default as privacybc3hOLgjsbMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/privacy.vue?macro=true";
import { default as termspQjEdFeQghMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/terms.vue?macro=true";
import { default as _91page_93uSPjstlimmMeta } from "/opt/buildhome/repo/pages/stores/create/[type]/emails/[page].vue?macro=true";
import { default as indexiFFIkqwAm1Meta } from "/opt/buildhome/repo/pages/stores/create/[type]/emails/index.vue?macro=true";
import { default as faqQUKq8fgm5qMeta } from "/opt/buildhome/repo/pages/stores/create/[type]/faq.vue?macro=true";
import { default as indexau5RcRKKLtMeta } from "/opt/buildhome/repo/pages/stores/create/[type]/index.vue?macro=true";
import { default as privacyedY47fiiILMeta } from "/opt/buildhome/repo/pages/stores/create/[type]/privacy.vue?macro=true";
import { default as terms44Dz2Mq9XHMeta } from "/opt/buildhome/repo/pages/stores/create/[type]/terms.vue?macro=true";
import { default as indexqgavoFdOYGMeta } from "/opt/buildhome/repo/pages/stores/index.vue?macro=true";
import { default as interstitial5WWieI7jjyMeta } from "/opt/buildhome/repo/pages/stores/interstitial.vue?macro=true";
import { default as storesls1CbGc5ygMeta } from "/opt/buildhome/repo/pages/stores.vue?macro=true";
import { default as settingsDRuGUEIRLTMeta } from "/opt/buildhome/repo/pages/terminal/auth/settings.vue?macro=true";
import { default as indexcH83g3VAfTMeta } from "/opt/buildhome/repo/pages/terminal/index.vue?macro=true";
import { default as _91reference_93huzq4y0x7HMeta } from "/opt/buildhome/repo/pages/terminal/redeem/[reference].vue?macro=true";
import { default as confirmationiqEfo3qVMwMeta } from "/opt/buildhome/repo/pages/terminal/redeem/confirmation.vue?macro=true";
import { default as indexmt1Ap7eunWMeta } from "/opt/buildhome/repo/pages/terminal/redeem/index.vue?macro=true";
import { default as _91redeemableId_931RYVedKbQ5Meta } from "/opt/buildhome/repo/pages/terminal/redeem/voucher/[reference]/[redeemableId].vue?macro=true";
import { default as indexkVc81AzHfGMeta } from "/opt/buildhome/repo/pages/terminal/redeem/voucher/[reference]/index.vue?macro=true";
import { default as completeQx0OMRnvVpMeta } from "/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/complete.vue?macro=true";
import { default as deliveryFFXgUJiu3SMeta } from "/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/delivery.vue?macro=true";
import { default as indexPFLNViMS4jMeta } from "/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/index.vue?macro=true";
import { default as enhancementsAx0FCwCYIcMeta } from "/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/enhancements.vue?macro=true";
import { default as index7mQcKrkyY0Meta } from "/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/index.vue?macro=true";
import { default as monetaryAHsqaIQrT0Meta } from "/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/monetary.vue?macro=true";
import { default as personalisationtav8gDSrD0Meta } from "/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/personalisation.vue?macro=true";
import { default as variantw0Bj4GZMoWMeta } from "/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/variant.vue?macro=true";
import { default as summaryrtmYmdA8hYMeta } from "/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/summary.vue?macro=true";
import { default as indexzNU1cA0Hw4Meta } from "/opt/buildhome/repo/pages/terminal/sell/[type]/index.vue?macro=true";
export default [
  {
    name: "auth-invalid-password-reset-token",
    path: "/auth/invalid-password-reset-token",
    component: () => import("/opt/buildhome/repo/pages/auth/invalid-password-reset-token.vue").then(m => m.default || m)
  },
  {
    name: "auth-login-accountSlug",
    path: "/auth/login/:accountSlug()",
    component: () => import("/opt/buildhome/repo/pages/auth/login/[accountSlug].vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/opt/buildhome/repo/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/opt/buildhome/repo/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-request-password-reset-confirmation",
    path: "/auth/request-password-reset-confirmation",
    component: () => import("/opt/buildhome/repo/pages/auth/request-password-reset-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "auth-request-password-reset",
    path: "/auth/request-password-reset",
    component: () => import("/opt/buildhome/repo/pages/auth/request-password-reset.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password-confirmation",
    path: "/auth/reset-password-confirmation",
    component: () => import("/opt/buildhome/repo/pages/auth/reset-password-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    component: () => import("/opt/buildhome/repo/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-settings",
    path: "/auth/settings",
    component: () => import("/opt/buildhome/repo/pages/auth/settings.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email-userId-hash",
    path: "/auth/verify-email/:userId()/:hash()",
    component: () => import("/opt/buildhome/repo/pages/auth/verify-email/[userId]/[hash].vue").then(m => m.default || m)
  },
  {
    name: categoriesi2G3xcJEDRMeta?.name,
    path: "/categories",
    component: () => import("/opt/buildhome/repo/pages/categories.vue").then(m => m.default || m),
    children: [
  {
    name: "categories-categoryId",
    path: ":categoryId()",
    component: () => import("/opt/buildhome/repo/pages/categories/[categoryId].vue").then(m => m.default || m)
  },
  {
    name: "categories-create-salesChannelId",
    path: "create/:salesChannelId()",
    component: () => import("/opt/buildhome/repo/pages/categories/create/[salesChannelId].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/categories/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "discounts-discountId-codes",
    path: "/discounts/:discountId()/codes",
    component: () => import("/opt/buildhome/repo/pages/discounts/[discountId]/codes.vue").then(m => m.default || m)
  },
  {
    name: "discounts-discountId",
    path: "/discounts/:discountId()",
    component: () => import("/opt/buildhome/repo/pages/discounts/[discountId]/index.vue").then(m => m.default || m)
  },
  {
    name: "discounts-create",
    path: "/discounts/create",
    component: () => import("/opt/buildhome/repo/pages/discounts/create.vue").then(m => m.default || m)
  },
  {
    name: "discounts",
    path: "/discounts",
    component: () => import("/opt/buildhome/repo/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    name: enhancementsyXoJzs6HxBMeta?.name,
    path: "/enhancements",
    component: () => import("/opt/buildhome/repo/pages/enhancements.vue").then(m => m.default || m),
    children: [
  {
    name: "enhancements-enhancementId",
    path: ":enhancementId()",
    component: () => import("/opt/buildhome/repo/pages/enhancements/[enhancementId].vue").then(m => m.default || m)
  },
  {
    name: "enhancements-create",
    path: "create",
    component: () => import("/opt/buildhome/repo/pages/enhancements/create.vue").then(m => m.default || m)
  },
  {
    name: "enhancements",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/enhancements/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation-invitationId",
    path: "/invitation/:invitationId()",
    component: () => import("/opt/buildhome/repo/pages/invitation/[invitationId].vue").then(m => m.default || m)
  },
  {
    name: "invitation-invalid",
    path: "/invitation/invalid",
    component: () => import("/opt/buildhome/repo/pages/invitation/invalid.vue").then(m => m.default || m)
  },
  {
    name: orderspVyfxG93eVMeta?.name,
    path: "/orders",
    component: () => import("/opt/buildhome/repo/pages/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "orders-orderId",
    path: ":orderId()",
    component: () => import("/opt/buildhome/repo/pages/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/orders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: products2T6Jgugpz6Meta?.name,
    path: "/products",
    component: () => import("/opt/buildhome/repo/pages/products.vue").then(m => m.default || m),
    children: [
  {
    name: "products-productId",
    path: ":productId()",
    component: () => import("/opt/buildhome/repo/pages/products/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: "products-productId-variants-variantId",
    path: ":productId()/variants/:variantId()",
    component: () => import("/opt/buildhome/repo/pages/products/[productId]/variants/[variantId].vue").then(m => m.default || m)
  },
  {
    name: "products-productId-variants-create",
    path: ":productId()/variants/create",
    component: () => import("/opt/buildhome/repo/pages/products/[productId]/variants/create.vue").then(m => m.default || m)
  },
  {
    name: "products-create-type",
    path: "create/:type()",
    component: () => import("/opt/buildhome/repo/pages/products/create/[type].vue").then(m => m.default || m)
  },
  {
    name: "products-create-variants-create",
    path: "create/variants/create",
    component: () => import("/opt/buildhome/repo/pages/products/create/variants/create.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/products/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: reportsXblRqgf8cKMeta?.name,
    path: "/reports",
    component: () => import("/opt/buildhome/repo/pages/reports.vue").then(m => m.default || m),
    children: [
  {
    name: "reports",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/reports/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settings4cDYwTsA31Meta?.name,
    path: "/settings",
    component: () => import("/opt/buildhome/repo/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-billing",
    path: "billing",
    component: () => import("/opt/buildhome/repo/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: email_45domainsnKawtjh0CKMeta?.name,
    path: "email-domains",
    component: () => import("/opt/buildhome/repo/pages/settings/email-domains.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-email-domains-hostname",
    path: ":hostname()",
    component: () => import("/opt/buildhome/repo/pages/settings/email-domains/[hostname].vue").then(m => m.default || m)
  },
  {
    name: "settings-email-domains",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/settings/email-domains/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings-general",
    path: "general",
    component: () => import("/opt/buildhome/repo/pages/settings/general.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-payment-gateways-connect-paypal",
    path: "payment-gateways/connect/paypal",
    component: () => import("/opt/buildhome/repo/pages/settings/payment-gateways/connect/paypal.vue").then(m => m.default || m)
  },
  {
    name: "settings-payment-gateways-connect-stripe",
    path: "payment-gateways/connect/stripe",
    component: () => import("/opt/buildhome/repo/pages/settings/payment-gateways/connect/stripe.vue").then(m => m.default || m)
  },
  {
    name: "settings-payment-gateways",
    path: "payment-gateways",
    component: () => import("/opt/buildhome/repo/pages/settings/payment-gateways/index.vue").then(m => m.default || m)
  },
  {
    name: redeem_45locations3gtKCkroFgMeta?.name,
    path: "redeem-locations",
    component: () => import("/opt/buildhome/repo/pages/settings/redeem-locations.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-redeem-locations-redeemLocationId",
    path: ":redeemLocationId()",
    component: () => import("/opt/buildhome/repo/pages/settings/redeem-locations/[redeemLocationId].vue").then(m => m.default || m)
  },
  {
    name: "settings-redeem-locations-create",
    path: "create",
    component: () => import("/opt/buildhome/repo/pages/settings/redeem-locations/create.vue").then(m => m.default || m)
  },
  {
    name: "settings-redeem-locations",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/settings/redeem-locations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userszZkKEWjH4lMeta?.name,
    path: "users",
    component: () => import("/opt/buildhome/repo/pages/settings/users.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-users-userId",
    path: ":userId()",
    component: () => import("/opt/buildhome/repo/pages/settings/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: "settings-users-create-email",
    path: "create/email",
    component: () => import("/opt/buildhome/repo/pages/settings/users/create/email.vue").then(m => m.default || m)
  },
  {
    name: "settings-users-create-username",
    path: "create/username",
    component: () => import("/opt/buildhome/repo/pages/settings/users/create/username.vue").then(m => m.default || m)
  },
  {
    name: "settings-users",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/settings/users/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-users-invitations-invitationId",
    path: "invitations/:invitationId()",
    component: () => import("/opt/buildhome/repo/pages/settings/users/invitations/[invitationId].vue").then(m => m.default || m)
  },
  {
    name: "settings-users-invitations",
    path: "invitations",
    component: () => import("/opt/buildhome/repo/pages/settings/users/invitations/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: storesls1CbGc5ygMeta?.name,
    path: "/stores",
    component: () => import("/opt/buildhome/repo/pages/stores.vue").then(m => m.default || m),
    children: [
  {
    name: "stores-salesChannelId-emails-page",
    path: ":salesChannelId()/emails/:page()",
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/emails/[page].vue").then(m => m.default || m)
  },
  {
    name: "stores-salesChannelId-emails",
    path: ":salesChannelId()/emails",
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/emails/index.vue").then(m => m.default || m)
  },
  {
    name: "stores-salesChannelId-faq",
    path: ":salesChannelId()/faq",
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/faq.vue").then(m => m.default || m)
  },
  {
    name: "stores-salesChannelId",
    path: ":salesChannelId()",
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/index.vue").then(m => m.default || m)
  },
  {
    name: "stores-salesChannelId-privacy",
    path: ":salesChannelId()/privacy",
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/privacy.vue").then(m => m.default || m)
  },
  {
    name: "stores-salesChannelId-terms",
    path: ":salesChannelId()/terms",
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/terms.vue").then(m => m.default || m)
  },
  {
    name: "stores-create-type-emails-page",
    path: "create/:type()/emails/:page()",
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/emails/[page].vue").then(m => m.default || m)
  },
  {
    name: "stores-create-type-emails",
    path: "create/:type()/emails",
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/emails/index.vue").then(m => m.default || m)
  },
  {
    name: "stores-create-type-faq",
    path: "create/:type()/faq",
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/faq.vue").then(m => m.default || m)
  },
  {
    name: "stores-create-type",
    path: "create/:type()",
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: "stores-create-type-privacy",
    path: "create/:type()/privacy",
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/privacy.vue").then(m => m.default || m)
  },
  {
    name: "stores-create-type-terms",
    path: "create/:type()/terms",
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/terms.vue").then(m => m.default || m)
  },
  {
    name: "stores",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/stores/index.vue").then(m => m.default || m)
  },
  {
    name: "stores-interstitial",
    path: "interstitial",
    component: () => import("/opt/buildhome/repo/pages/stores/interstitial.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "terminal-auth-settings",
    path: "/terminal/auth/settings",
    component: () => import("/opt/buildhome/repo/pages/terminal/auth/settings.vue").then(m => m.default || m)
  },
  {
    name: "terminal",
    path: "/terminal",
    component: () => import("/opt/buildhome/repo/pages/terminal/index.vue").then(m => m.default || m)
  },
  {
    name: "terminal-redeem-reference",
    path: "/terminal/redeem/:reference()",
    component: () => import("/opt/buildhome/repo/pages/terminal/redeem/[reference].vue").then(m => m.default || m)
  },
  {
    name: "terminal-redeem-confirmation",
    path: "/terminal/redeem/confirmation",
    component: () => import("/opt/buildhome/repo/pages/terminal/redeem/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "terminal-redeem",
    path: "/terminal/redeem",
    component: () => import("/opt/buildhome/repo/pages/terminal/redeem/index.vue").then(m => m.default || m)
  },
  {
    name: "terminal-redeem-voucher-reference-redeemableId",
    path: "/terminal/redeem/voucher/:reference()/:redeemableId()",
    component: () => import("/opt/buildhome/repo/pages/terminal/redeem/voucher/[reference]/[redeemableId].vue").then(m => m.default || m)
  },
  {
    name: "terminal-redeem-voucher-reference",
    path: "/terminal/redeem/voucher/:reference()",
    component: () => import("/opt/buildhome/repo/pages/terminal/redeem/voucher/[reference]/index.vue").then(m => m.default || m)
  },
  {
    name: "terminal-sell-type-salesChannelId-complete",
    path: "/terminal/sell/:type()/:salesChannelId()/complete",
    component: () => import("/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/complete.vue").then(m => m.default || m)
  },
  {
    name: "terminal-sell-type-salesChannelId-delivery",
    path: "/terminal/sell/:type()/:salesChannelId()/delivery",
    component: () => import("/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/delivery.vue").then(m => m.default || m)
  },
  {
    name: "terminal-sell-type-salesChannelId",
    path: "/terminal/sell/:type()/:salesChannelId()",
    component: () => import("/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/index.vue").then(m => m.default || m)
  },
  {
    name: "terminal-sell-type-salesChannelId-p-productId-enhancements",
    path: "/terminal/sell/:type()/:salesChannelId()/p/:productId()/enhancements",
    component: () => import("/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/enhancements.vue").then(m => m.default || m)
  },
  {
    name: "terminal-sell-type-salesChannelId-p-productId",
    path: "/terminal/sell/:type()/:salesChannelId()/p/:productId()",
    component: () => import("/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: "terminal-sell-type-salesChannelId-p-productId-monetary",
    path: "/terminal/sell/:type()/:salesChannelId()/p/:productId()/monetary",
    component: () => import("/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/monetary.vue").then(m => m.default || m)
  },
  {
    name: "terminal-sell-type-salesChannelId-p-productId-personalisation",
    path: "/terminal/sell/:type()/:salesChannelId()/p/:productId()/personalisation",
    component: () => import("/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/personalisation.vue").then(m => m.default || m)
  },
  {
    name: "terminal-sell-type-salesChannelId-p-productId-variant",
    path: "/terminal/sell/:type()/:salesChannelId()/p/:productId()/variant",
    component: () => import("/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/p/[productId]/variant.vue").then(m => m.default || m)
  },
  {
    name: "terminal-sell-type-salesChannelId-summary",
    path: "/terminal/sell/:type()/:salesChannelId()/summary",
    component: () => import("/opt/buildhome/repo/pages/terminal/sell/[type]/[salesChannelId]/summary.vue").then(m => m.default || m)
  },
  {
    name: "terminal-sell-type",
    path: "/terminal/sell/:type()",
    component: () => import("/opt/buildhome/repo/pages/terminal/sell/[type]/index.vue").then(m => m.default || m)
  }
]